.CHLHeader {
	display: flex;
	align-items: center;

	.mainTopTitle {
		flex-grow: 1;
	}
}

.CHLMain {

	display: flex;
	flex-direction: column;
	padding: 16px;
	height: calc(var(--page-height) - 67px);
	overflow-y: auto;
	padding-top: 0;

	.CHLdiv {
		display: flex;
		flex-direction: column;
		flex: 1;


	}


	label {
		display: flex;
		justify-content: space-between;
		width: 100%;
		flex-direction: row;
		border-bottom: 1px solid #EBEBDE;
		padding: 15px 0;


		.CHLButtonLang {

			background: transparent;
			border: 0;
			font-size: 20;
			display: flex;
			align-items: center;
			gap: 10px;


			.CHLImage {
				border-radius: 50px;
				background-size: cover;
				height: 30px;
				width: 30px;
			}
		}
	}
}