.mainTopTitle {
	padding: 17px;
	font-size: 17px;
	font-weight: 700;
	line-height: 33px;
	display: flex;
	justify-content: center;
	align-items: center
}

.cabinetRowText {
	padding-left: 16px;
}

.flexGrow {
	flex-grow: 1;
}

.buttonExit {
	margin-top: auto;

	.cRed {
		background-color: #fd4b13;
	}
}

.cabinetModalBt {
	display: flex;
	gap: 10px;
}

.cabinetModals {
	display: flex;
	flex-direction: column;
	gap: 5px;

	.cabinetTextInput {
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid #ebebde;

		p {
			color: #b3b3a5;
			font-size: 15px;
			margin-bottom: 5px;
		}
	}

	input {
		font-size: large;
		border: none;
		outline: none;
		color: #515142;
	}
}

.cabinetNamesError {
	color: red;
}

.cabinet-modal .ant-modal-mask{
	background: rgba(50, 50, 47, 0.70);
	backdrop-filter: blur(5px);
}

.cabinet-modal .ant-modal-content {
	border-radius: 12px;
	margin: 0 30px;

}

.cabinetNamesSuccess {
	color: green;
}

.CabMain {

	height: calc(var(--page-height) - 67px);
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow-y: scroll;

	.cabinetRow {
		padding: 16px 0;
		border-bottom: 1px solid #ebebde;
		color: #b3b3a5;
		display: flex;
		align-items: center;
		width: 100%;


		.cabinetImgContainer {
			position: relative;
			width: 70px;
			height: 70px;
			min-width: 70px;
			border-radius: 50%;
			overflow: hidden;

			.cabinetImg {
				width: 100%;
				height: 100%;
				object-fit: cover;
				opacity: 1;
			}

			.cabinetFile {
				display: flex;
				justify-content: center;
				width: 100%;
				height: 25px;
				position: absolute;
				top: 70%;
				background-color: rgba(0, 0, 0, .5);
				transition: all 0.5s;
				transform: translateY(100%);

				* {
					color: #fff;
				}
			}

			&:hover {
				.cabinetFile {
					transform: translateY(0);
				}
			}
		}

		.cabinetUser {
			font-weight: 500;
			font-size: 17px;
			line-height: 20px;
			padding-top: 10px;
			color: #515142;
		}

		.dark {
			color: #515142;
		}

		.cGreen {
			padding-left: 0.5rem;
			color: #73cc2c;
		}
	}

	.center {
		display: flex;
		align-items: center;
	}
}