.QSTopTitle {
	padding: 17px;
	font-size: 17px;
	font-weight: 700;
	line-height: 33px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #282826;

	.QSTitle {
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: center;
	}

	.QSClients {
		font-size: 13px;
		padding: 0 3px;
		border-radius: 12px;
	}
}

.QSButtonModal {
	display: flex;
	gap: 10px;
	padding: 10px 0;

	button {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.red {
		background-color: #E0503D;
	}

	.yellow {
		background-color: #F3BA26;
	}
}

.QSmain {
	display: flex;
	flex-direction: column;
	height: calc(var(--page-height) - 67px);


	.QSTopNames {
		display: flex;
		color: #B2B2A5;
		font-size: 15px;
	}

	.QSTopNamesLeft {
		display: flex;
		justify-content: flex-end;
		color: #B2B2A5;
		font-size: 15px;
	}

	.QSBottomNum {
		color: #515142;
		font-size: 17px;
	}

	.icon {
		color: #DEDED5
	}

	.QSName {
		font-size: 19px;
		font-weight: bold;
		color: #282826;
	}

	.QSDataMain {
		display: flex;
		flex-direction: column;
		flex: 1;

		.QSDataIconTop {

			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 20px;
			padding-bottom: 15px;
			gap: 15px;
			border-bottom: 1px solid;
			border-bottom-color: #EBEBDE;


			.QSQueueNum {
				display: flex;
				gap: 15px;
			}
		}


		.QSDataIcon {
			display: flex;
			align-items: center;
			padding-top: 20px;
			padding-bottom: 15px;
			gap: 15px;
			border-bottom: 1px solid;
			border-bottom-color: #EBEBDE;

			.QSBreakTime {
				color: #515142;
				font-size: 13px;
			}
		}

		.QSDataIcon-noBorder {
			border-bottom: none;
		}

		.QSCurrent {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			flex: 1;
			color: #B2B2A5;

			.QSCurNumber {
				font-size: 33px;
				color: #282826;
				font-weight: bold;
			}

			.todayNotWork {
				text-align: center;
				font-size: 50px;
				font-weight: bolder;
			}
		}

	}

	.QSUserInfo {

		.QSUserName {
			color: #000;
		}
	}

	.queueSchemeBt {
		display: flex;
		flex-direction: column;
		gap: 10px;

		.QSHereSkip {
			display: flex;
			gap: 15px;

			.QSHereSkipBt {
				gap: 6px;
				display: flex;
				justify-content: center;
				align-items: center;
				flex: 1;
				height: 50px;
				border-radius: 12px;
				border: none;
				font-weight: bold;
				font-size: 17px;
				color: #FFFFF5;
				cursor: pointer;

				&.green {
					background-color: #8DD636;
				}

				&.red {
					background-color: #E0503D;
				}

			}

		}

		.QSButtonNext {
			flex: 1;
			display: flex;
			gap: 10px;
			justify-content: center;
			align-items: center;
			height: 50px;
			border-radius: 12px;
			border: 1px solid;
			background-color: #FFFFF5;
			font-size: 17px;
			font-weight: bold;
			border-color: #B5B5AD;
			cursor: pointer;





			.iconNext {
				color: #000000;
			}


			.disabled {
				opacity: 0.3;
			}
		}

		.QSBtPauseEnd {
			display: flex;
			gap: 1rem;

			.QSButton {
				gap: 6px;
				display: flex;
				justify-content: center;
				align-items: center;
				flex: 1;
				height: 50px;
				border-radius: 12px;
				border: none;
				font-weight: bold;
				font-size: 17px;
				color: #FFFFF5;
				cursor: pointer;


				&.yellow {
					background-color: #F3BA26;
				}

				&.red {
					background-color: #E0503D;
				}

				&.green {
					background-color: #8DD636;
				}
			}


		}
	}
}

@media (max-width: 600px) {
	.todayNotWork {
		font-size: 30px !important;
	}
}