a:-webkit-any-link,
a:hover {
	text-decoration: none;
	color: inherit
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0
}

p,
h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	line-height: 1;
}

ul,
ol {
	list-style: none
}

.Toastify__toast {
	touch-action: none;
	-ms-touch-action: none;
}

html {
	height: 100%;
}

body {
	display: flex;
	font-size: 1rem;
	line-height: 1.2;
	background-color: #FFFFF5;
	min-width: 100%;
	flex-direction: column;

	#root {
		overflow-x: auto;
		height: 100%;

		header {
			.mainTopTitle {
				padding: 17px;
				font-size: 17px;
				font-weight: 700;
				line-height: 33px;
				display: flex;
				justify-content: center;
				align-items: center
			}
		}

		main {
			padding: 0 1rem 1rem;
		}
	}
}

[disabled] {
	cursor: not-allowed !important;
	opacity: 0.75 !important;
}

@keyframes startAnimate {
	from {
		transform: scale(1);
	}

	85% {
		transform: scale(1.15);
	}

	to {
		transform: scale(1);

	}
}