header.home {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #fffff5;
	height: 165px;
}

.logoCont {
	margin: 0 auto;
	height: 5rem;
}

.logoCont img {
	margin: 20px 0;
}

.searchCont {
	display: flex;
	margin: 0 10px;
	width: calc(100% - 20px);
	min-height: 44px;
	z-index: 6;
}

.searchCont.hint {
	background-color: #F4F4E5;
	padding: 10px;
	width: calc(100% - 20px);
	border-radius: 5px;
	position: fixed;
	top: 124px;
	box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.1);
	border: 1px solid #DEDED5;
}

.searchCont.hint:active {
	filter: grayscale(.8);
}

.searchCont input {
	border: 1px solid #DEDED5;
	border-right-width: 0;
	border-radius: 12px 0 0 12px;
	outline: 0;
	padding: 10px;
	background-color: #F4F4E5;
	flex-grow: 1;
}

.searchCont svg {
	padding: 10px;
	border: 1px solid #DEDED5;
	border-left-width: 0;
	border-radius: 0 12px 12px 0;
	background-color: #F4F4E5;
	width: 49px;
	height: 44px;
}

.searchCont i {
	margin: 6px;
	color: #979791;
	transition: 0.5s;
}

.textCont {
	color: #BDBDB2;
	font-size: 13px;
	line-height: 16px;
	padding: 20px 0 0 1rem;
	text-transform: uppercase;
	border-bottom: 1px solid #ebebeb
}

.infoLogo {
	position: fixed;
	bottom: 100px;
	right: 20px;
	border-radius: 50%;
	padding: 15px 20px;
	background-color: #FAC232;
	box-shadow: 0px 5px 17px #ffe49d
}

main::-webkit-scrollbar {
	width: 0;
}

main.home {
	height: calc(var(--page-height) - 165px);
	overflow-y: auto;
	display: flex;
	flex-direction: column;
}

.recordsIndex {
	border-bottom: 1px solid #EBEBDE;
}

.recordsIndex>div {
	padding-bottom: 20px;
}

.queueName {
	font-weight: 600;
	font-size: 19px;
	line-height: 25px;
	color: #282826
}

.queueTime {
	font-size: 17px;
	line-height: 20px;
	color: #E48C24
}

.queueNum {
	border: 1px solid #8ED727;
	border-radius: 7px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 77px;
	height: 50px;
}

.queueCrTimeText {
	font-size: 15px;
	line-height: 18px;
	color: #B3B3A5;
}

.queueCrTime {
	font-size: 17px;
	line-height: 20px;
	color: #515142
}

.queueCurrentTime {
	display: flex;
	flex-flow: column;
	align-items: center;
}

.queueBtn {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	font-size: 19px;
	line-height: 25px;
	padding: 8px 12px;
	border: 0;
}

.queueBtn.error {
	color: #fff;
	background-color: #E0503D
}

.queueBtn.active {
	color: #fff;
	background-color: #71B725
}

.queueBtn.wait {
	color: #fff;
	background-color: #F3BA26
}

.queueBtn.bordered {
	background-color: transparent;
	border: 1px solid #B5B5AD;
	color: #000;
}

.queueBtn:hover {
	color: #fff
}

.queueBtn.bordered:hover {
	color: #000
}

.bgGray {
	background-color: #ADADA5;
	color: #fff
}

.emptyHome {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	align-items: center;
	justify-content: center;
}

.emptyHomeHeader {
	font-size: 23px;
	line-height: 27px;
	text-align: center;
	padding: 15px;
	color: #282826
}

.emptyHomeText {
	font-size: 19px;
	line-height: 25px;
	text-align: center;
	padding: 0 15px;
	color: #BDBDB2
}

.cGray {
	color: #DEDED5;
}

.searchCont .focused {
	border-color: #7CD02B;
}

i.hideFocus {
	font-size: 0;
	margin: 0;
}

[data-tipforhome] {
	position: fixed;
	padding-top: 125px;
	width: -webkit-fill-available;
	height: calc(var(--page-height) + 80px);
	z-index: 5;
	opacity: 1;
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	transition: all 0.5s linear;
}

@media screen and (max-width: 350px) {
	.queueBtn {
		font-size: 16px
	}
}