.navbar {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #32322F;
	color: #696964;
	padding: 15px 20px;
	height: 80px;
	width: 100%;

	&>div {
		max-width: 1024px;
		margin: 0 auto;

		@media (max-width: 1200px) {
			max-width: 1024px;
		}

		@media (max-width: 1024px) {
			max-width: 768px;
		}

		@media (max-width: 768px) {
			max-width: 100%;
		}
	}

	.ant-col {
		text-align: center;
	}

	.click {
		color: white;
	}
}

.search {
	width: 100%;

	.searchNavbatText {
		font-size: 22px;
		font-weight: bold;
		color: #75CA2C;
		font-family: Geometria;
	}

	.searchKutmangText {
		font-size: 13px;
		color: #282826;
		font-weight: bold;
		font-family: Geometria;
	}

	.searchInput {
		// height: 45px;
		flex: 1;
		display: flex;
		z-index: 6;

		input {
			color: #BDBDB2;
			border: 1px solid #DEDED5;
			border-right: none;
			background-color: #F4F4E5;
			outline: none;

			padding: 12.5px 16px;
			border-radius: 12px 0 0 12px;
			font-size: 17px;
			width: 100%;

			&:focus,
			&:active {
				border: 1px solid #7CD02B;
				border-right: none;
				color: #000;
			}

			&::placeholder {
				color: #BDBDB2;
			}
		}
	}

	.searchInputSubmit {
		padding: 12.5px 16px;
		border: 1px solid #DEDED5;
		border-left: none;
		background-color: #F4F4E5;
		border-radius: 0 12px 12px 0;
	}

	.searchQR {
		padding: 0 20px;
		box-sizing: border-box;
		z-index: 6;
	}

	.searchInputComp {
		display: flex;
		padding-left: 10px;
		height: 44px;
	}
}

.searchInput input:focus+.searchInputSubmit,
.searchInput input:active+.searchInputSubmit {
	border-color: #7CD02B;
	border-left: none;
}

[data-submit] {
	background-color: #8DD636;
	font-size: 20px;
	font-weight: bold;
	border-radius: 12px;
	border: 0;
	width: 100%;
	height: 50px;
	color: #fff;
	cursor: pointer;
}

[data-submit]:active {
	opacity: .7
}

[data-submit]:disabled {
	cursor: not-allowed;
	opacity: .8;
}

.QANew {
	position: fixed;
	width: 100%;
	bottom: 80px;
	height: 72px;
}

.QANewBtn {
	display: flex;
	justify-content: center;
	align-items: center;
}