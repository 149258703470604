.QAMain {
	display: flex;
	flex-direction: column;
	flex: 1;
	height: calc(var(--page-height) - 117px);
}



.QCTopTitle {
	padding: 17px;
	font-size: 17px;
	font-weight: 700;
	line-height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	flex-direction: column;

	.QCTopText {
		font-weight: 700;
		font-size: 17px;
		line-height: 20px;
		color: #282826;
	}

	.QCTopStep {
		font-size: 15px;
		font-weight: 400;
		line-height: 20px;
		color: #515142;
	}
}

.queueName {
	display: flex;
	justify-content: space-between;
	font-size: 18px;
	color: #282826;
}

.QA {
	display: flex;
	align-items: center;
	gap: 10px;
	border-bottom: 1px solid;
	border-bottom-color: #EBEBDE;
	// flex: 1;

}

.QAMainBody {
	display: flex;
	flex-direction: column;
	height: 80%;
	flex: 1;
	overflow-y: auto;
}

.queueMain {
	flex: 1;

	.QAProp {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 30px;

		.queueDataClient {
			display: flex;
			justify-content: flex-end;
		}

		.queueDataIcon {
			flex: 1;
			display: flex;
			margin-top: 20px;
			margin-bottom: 20px;
			gap: 16px;



			&.flex-2 {
				flex: 2;
			}

			.queueIcon {
				color: #DEDED5;
				display: flex;
				align-items: center;
			}

			.queueData {
				display: flex;
				flex-direction: column;
				gap: 3px;

				.queueDataText {
					font-size: 15px;
					color: #B2B2A5;
					text-align: center;
				}

				.queueDataInner {
					text-align: center;
					font-size: 17px;
					color: #515142;
					font-weight: 500;
				}
			}
		}
	}




	.queueCurrent {
		display: flex;
		flex-direction: column;
		align-items: center;

		.queueCurText {
			font-size: 15px;
			color: #B2B2A5;
			text-align: center;
		}

		.queueCurNumb {

			font-weight: bold;
			font-size: 21px;
			color: #282826;
		}

		.queueCurNumb2 {
			font-weight: bold;
			font-size: 21px;
			color: #282826;
			text-align: center;
		}

		@media (max-width: 600px) {

			.queueCurNumb2 {
				font-size: 15px;
			}

			.queueCurText {
				font-size: 12px;
			}

			.queueDataText {
				font-size: 12px;
			}
		}
	}


}