header.CSHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1rem;

	.mainTopTitle {
		flex-grow: 1;
	}
}

.QCMain {
	display: flex;
	flex-direction: column;
	padding: 16px;
	height: calc(var(--page-height) - 67px);
	overflow-y: auto;
	justify-content: space-between;

	.error-message {
		color: red !important;
		;
		font-size: 14px;
	}

	.CSQueueNameLabel {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		border-bottom: 1px solid #EBEBDE;
		padding: 15px 0;

		span {
			display: flex;
			align-items: center;
			color: #515142;
		}


		.CSInputDiv {
			display: flex;
			justify-content: space-between;
			align-items: center;

			input {
				font-size: 17px;
				color: #515142;
				text-align: calc(100% - 50px);

			}
		}
	}

	.CSDescriptionLabel {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		border-bottom: 1px solid #EBEBDE;
		padding: 15px 0;
		gap: 10px;

		.error-message {
			color: red !important;
			;
			font-size: 14px;
		}

		span {
			display: flex;
			align-items: center;
			color: #515142;
		}




		input {
			font-size: 17px;
			color: #515142;
		}
	}

	.CSDurationFeeLabel {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		border-bottom: 1px solid #EBEBDE;
		padding: 15px 0;
		gap: 10px;

		.error-message {
			color: red !important;
			;
			font-size: 14px;
		}

		span {
			display: flex;
			align-items: center;
			color: #515142;
		}




		input {
			font-size: 17px;
			color: #515142;
			text-align: right;
		}
	}

	.CSIsAgreedLabel {
		display: flex;
		justify-content: space-between;
		width: 100%;
		border-bottom: 1px solid #EBEBDE;
		padding: 5px 0;
		align-items: center;

	}


}