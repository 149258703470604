.monitoringMain {


	.monitoringName {
		font-size: 19px;
		font-weight: 600;
		color: #282826;
		margin-bottom: 20px;
	}

	.monitoringBody {

		border-bottom: 1px solid;
		border-bottom-color: #EBEBDE;
		padding-bottom: 15px;
		padding-top: 15px;
	}

	.monitoringInfo {

		display: flex;
		justify-content: space-between;

		.monitoringIcon {
			display: flex;
			align-items: center;

			.monitoringDescription {
				display: flex;
				flex-direction: column;
				gap: 5px;
			}
		}

		.monitoringCountsUnique {
			display: flex;
			gap: 1rem;

			.monitoringCU {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			.monitoringText {
				font-size: 15px;
				color: #B2B2A5;
				text-align: center;
			}

			.monitoringNum {
				font-weight: bold;
				font-size: 21px;
				color: #282826;
			}

		}
	}
}