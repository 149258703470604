@font-face {
	font-family: 'Geometria';
	src: url('./fonts/Geometria.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'Geometria';
	src: url('./fonts/Geometria-Bold.ttf') format('truetype');
	font-weight: bold;
}

@font-face {
	font-family: 'SFProDisplay';
	src: url('./fonts/SF-Pro-Display-Regular.otf') format('opentype');
	font-weight: normal;
}

@font-face {
	font-family: 'SFProDisplay';
	src: url('./fonts/SF-Pro-Display-Medium.otf') format('opentype');
	font-weight: 500;
}

@font-face {
	font-family: 'SFProDisplay';
	src: url('./fonts/SF-Pro-Display-Bold.otf') format('opentype');
	font-weight: bold;
}

@font-face {
	font-family: 'SFProDisplay';
	src: url('./fonts/SF-Pro-Display-Semibold.otf') format('opentype');
	font-weight: 600;
}

body {
	margin: 0;
	font-family: 'SFProDisplay';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*,
::after,
::before {
	box-sizing: border-box;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}