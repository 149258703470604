.ChatBody {
	display: flex;
	align-items: center;
	margin-bottom: 15px;

	.ChatImgContainer {
		position: relative;
		height: 52px;
		width: 52px;


		.ChatImg {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			object-fit: cover;
			background-color: #000E08;
			border: 2px solid red;
		}

		.ChatImg2{
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			top: 50%;
			left: 50%;
			transform: translate(-20%,-20%);
			border: 2px solid red;
		}

		
	}

	.ChatName {
		display: flex;
		flex-direction: column;
		flex: 1;
		margin-left: 25px;
		gap: 6px;

		.ChatNameText {
			font-size: 20px;
			font-weight: 600;
			color: #000E08;
		}

		.ChatNameTextS {
			color: #797C7B;
			font-size: 12px;
		}


	}

	.ChatMin {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 7px;

		.ChatMinNum {
			font-size: 12px;
			color: #797C7B;
		}


		.ChatUnreadCon {

			width: 22px;
			height: 22px;
			background-color: #F04A4C;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			.ChatUnread {
				font-size: 12px;
				color: #ffffff;

			}
		}
	}

}