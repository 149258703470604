.clientsMain {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #ddd;



	.clientsMainBody {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.clientsUserInfoText {
			color: #ddd;
			margin-bottom: 5px;
		}

		.clientsUser {

			display: flex;
			align-items: center;

			.clientsUserInfo {
				color: #000;
				max-width: 70px;
				text-align: center;
			}
		}



		.clientsStatusTex {
			color: #ddd;
			margin-bottom: 5px;
		}

		.clientsStatus {
			color: #8ed727;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
		}

		.iconGreen {
			color: #71b725;
		}

		.iconYellow {
			color: #e4ae23;
		}

		.iconGray {
			color: #b0b0a2;
		}

		.iconOrange {
			color: #f97125;
		}

		.iconRed {
			color: #f03e26;
		}

		.hisHeadChoise h4.h4a {
			color: #282828;
		}

		.clientsNumber {

			border-radius: 7px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 50px;
			color: #515142;
			width: 55px;

		}

		.clientsStatusNumber {
			display: flex;
			align-items: center;
			flex-grow: 1;
			justify-content: space-between;


		}
	}
}

.clientsStatusDiv {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90px;
}

.clientsDate {
	display: flex;
	width: 80px;
	text-align: center;
}

.mainTopTitle {
	flex-grow: 1;
}

.clientsHeaderDate {
	display: flex;
	justify-content: center;
	width: 80px;
}

.clientsMainHeader {
	display: flex;
	justify-content: space-between;
	color: #515142;

	.clientsHeaderUser {
		min-width: 70px;
		text-align: center;
	}

	.clientsmainHeaderStatus {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 20px;
		flex-grow: 1;





		.clientsHeaderNumber {
			width: 55px;
		}
	}

}


.clientsHeaderStatus {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90px;
}

.clientsNoClients {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;

	h2 {
		color: #B2B2A5;
		font-size: 32px;
	}
}