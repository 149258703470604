header.QCHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1rem;
}


.QCAddPhoto {
	display: flex;
	gap: 10px;
	flex-direction: column;
	padding-bottom: 15px;

	h1 {
		font-weight: 700;
		font-size: 23px;
		line-height: 33px;
	}

	.QCAddPhotoLabel {
		width: 100%;
		height: 160px;
		background-color: #E3E3D4;
		border-radius: 12px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 11px;

		.queueImage {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 12px;
		}

		i {
			color: #979791;

		}

		span {
			color: #B2B2A5;
		}
	}
}

.QCWorkDays {
	display: flex;
	flex-direction: column;
	gap: 15px;

	h2 {
		padding-top: 20px;
		font-weight: 400;
		font-size: 17px;
		line-height: 20px;
		color: #515142;
	}


	.QCWorkDaysBody {
		display: flex;
		gap: 10px;
		flex-wrap: wrap;
		border-bottom: 1px solid #ebebde;
		padding-bottom: 15px;


		.QCWorkDaysBodyItem {
			display: flex;
			justify-content: center;
			align-items: center;
			min-width: 60px;
			height: 40px;
			background-color: #EEEEE3;
			border-radius: 6px;
			cursor: pointer;
		}

		.Clicked {
			background-color: #8dd636;

			span {
				color: white;
			}

		}

	}
}

.QCWorkHours {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #ebebde;
	padding-bottom: 15px;



	h2 {
		font-weight: 400;
		font-size: 17px;
		line-height: 20px;
		color: #515142;
		padding: 20px 0;
	}

}

.QCWorkHoursBody {
	.QCWorkHoursBodyItem {
		display: flex;


		.borderLeft {
			border-left: 1px solid #ebebde;
			padding-left: 10px;
		}

		.QCWorkHoursLabel {
			height: 30px;
			flex: 1;
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 10px;

			span {
				display: flex;
			}

			input[type="time"]::-webkit-calendar-picker-indicator {
				background: none;
			}

			input {
				display: flex;
				color: #8A8A7F;
				font-family: SF Pro Display;
				font-size: 17px;
				border: none;
			}
		}
	}
}



.QCPhoneNumber {
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid #ebebde;
	align-items: center;
	padding: 10px 0;
	justify-content: space-between;

	span {
		display: flex;
		flex: 1;
		color: #515142;
		font-weight: 400;
		height: 40px;
		align-items: center;
	}

	input {
		flex: 1;
		color: #515142;


		&::placeholder {
			color: #8A8A7F;
			font-size: 17px;
			font-weight: 400;
		}
	}
}


.QCMap {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
	border-bottom: 1px solid #ebebde;

	.QCMapTitle {
		display: flex;
		align-items: center;
		height: 40px;
		color: #515142;
	}

	.QCMapChoose {
		display: flex;
		align-items: center;
		height: 40px;
		color: #8A8A7F;
	}
}

.btnSubmit {
	height: 50px;
}


.QCMapDescription {
	display: flex;
	padding: 10px 0;
	border-bottom: 1px solid #ebebde;
	gap: 3px;

	span {
		display: flex;
		align-items: center;
		color: #515142;
		font-weight: 400;
		font-size: 17px;
		line-height: 20px;
	}

	input {
		flex: 1;
		color: #515142;
		font-size: 17px;
		font-weight: 400;
		border: none;
		height: 50px !important;

		&::placeholder {
			font-size: 16px;
			white-space: pre-wrap;
			overflow-wrap: break-word;
			display: block;

		}
	}
}

main.QCMain {
	display: flex;
	flex-direction: column;
	padding: 16px;
	height: calc(var(--page-height) - 67px);
	overflow-y: auto;
	justify-content: space-between;
}

.QCMain label {
	display: flex;
	justify-content: space-between;
}

.QCMain span {
	font-size: 17px;
	line-height: 20px;
	color: #515142;
}

.custom-lang-dropdown-qcmain {
	border: none;
}

.custom-lang-dropdown-qcmain img {
	border-radius: 50%;
	height: 40px;
}

.custom-lang-dropdown-qcmain .dropdownitem {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.QCMain .inputDiv {
	display: flex;
	gap: 20px;
	border-bottom: 1px solid #ebebde;
	justify-content: space-between;
	width: 100%;
	padding-bottom: 10px;

	input {
		font-weight: 400;
		font-size: 17px;
		line-height: 20px;

		&::placeholder {
			font-weight: 400;
			font-size: 17px;
			line-height: 20px;
		}
	}

	button {
		border: 0;
		outline: 0;
		padding: 0;
		background-color: transparent;
	}
}

.QCMain input {
	border: 0;
	outline: 0;
	background-color: transparent;
	height: 40px;

	&:focus-visible {
		outline: 0;
		border-bottom: 1px solid #7cd02b;
	}
}

.QCMain input:not(.QCTime):not(#checkb0x) {
	flex-grow: 1;
}

.QCTimeLab {
	display: flex;
	width: 50%;
}

.QCTime {
	width: 100%;
}

#checkb0x {
	width: 62px;
	height: 32px;
	border-radius: 25px;
	position: relative;
	background: #adada5;
	transition: all 0.2s;
	appearance: none;
	margin: 14px 0;
}

#checkb0x::after {
	content: '';
	position: absolute;
	top: 2px;
	left: 2px;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	background: white;
	box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
	transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
}

#checkb0x:checked::after {
	transform: translatex(30px);
}

#checkb0x:checked {
	background: #8dd636;
}

.min-h-0 {
	min-height: 0 !important;
	max-height: 0 !important;
}

[drop] {
	overflow: hidden;
	transition: 0.5s;
	transition-timing-function: cubic-bezier(1, 0, 0, 1);
	min-height: 60px;
	max-height: 60px;
}