:root {
	--chatone-main-height: 0px
}

.ChatOneHeader {
	display: flex;
	align-items: center;
	padding: 1rem;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #fffff5;


	.ChatOneBackicon {
		padding: 5px;
		border-radius: 50%;
	}

	.ChatOneImgStatus {

		.ChatOneImgCon {
			position: relative;
			width: 44px;
			height: 44px;
			margin-left: 16px;

			.ChatOneImg {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				object-fit: cover;
				background-color: black;
			}

			.ChatOneStatus {
				position: absolute;
				bottom: 0;
				right: 0;
				width: 13px;
				height: 13px;
				background-color: #8DD636;
				border-radius: 50%;
				border: 2px solid white;
			}
		}
	}

	.ChatOneNameActive {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 6px;
		margin-left: 12px;

		.ChatOneName {
			font-size: 16px;
			font-weight: 600;
		}

		.ChatOneActive {
			font-size: 12px;
			color: #797C7B;
		}
	}

	.ChatOneIcons {
		display: flex;
		gap: 5px;

		* {
			border-radius: 50%;
			padding: 5px;
		}
	}
}

.ChatOneMain {
	background-image: url('https://yt3.ggpht.com/ytc/AKedOLSR3I_WQMTkOXIX07n5aZGbQaqYiGXJvRqXhXBiZA=s900-c-k-c0x00ffffff-no-rj');
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	height: calc(100% - 76px - var(--chatone-main-height));
	margin-top: 76px;
	margin-top: 65px;

	flex: 1;
	overflow-y: auto;
	padding-top: 16px !important;

	.ChatOneMessages {
		display: flex;
		flex-direction: column;

		.ChatOneDay {
			margin-top: 15px;
			display: flex;
			justify-content: center;

			.ChatOneDate {
				border-radius: 5px;
				background-color: #797C7B;
				width: fit-content;
				padding: 3px;
			}
		}


		.ChatOneMes {
			display: flex;
			flex-direction: column;
			padding: 5px;
			border-radius: 10px;
			max-width: 500px;
			word-wrap: break-word;
			word-break: break-word;
			display: flex;

			.ChatOneMesTime {
				display: flex;
				justify-content: flex-end;
				font-size: 10px;

			}

			&.sent {
				background-color: #dcf8c6;
				border-bottom-right-radius: 0%;
				align-self: flex-end;
			}

			&.recieved {
				background-color: #fff;
				align-self: flex-start;
				border-bottom-left-radius: 0%;
			}


		}
	}
}


.ChatOneFooter {
	padding: 10px;
	display: flex;
	align-items: flex-end;
	gap: 16px;
	position: fixed;
	bottom: 80px;
	left: 0;
	width: 100%;
	background-color: #fffff5;

	.ChatOneFtIcon {
		padding: 5px;
		border-radius: 50%;
	}

	.ChatOneFtInput {
		::-webkit-scrollbar {
			width: 8px;
		}

		// ::-webkit-scrollbar-track {
		// 	// background: #f1f1f1;
		// }

		::-webkit-scrollbar-thumb {
			background: #888;
			border-radius: 3px 12px 12px 3px;
		}

		::-webkit-scrollbar-thumb:hover {
			background: #8DD636;
		}

		flex: 1;

		.ChatOneInput {
			width: 100%;
			padding: 5px 15px;
			border: none;
			background-color: #F3F6F6;
			min-height: 40px;
			max-height: 240px;
			border-radius: 12px;
			resize: none;
			overflow: hidden;
			font-size: 24px;
			font-family: 'SFProDisplay';
			outline-color: #8DD636;
			overflow-y: auto;
		}
	}

	.ChatOneFtSend {
		background-color: #8DD636;
		padding: 5px;
		border-radius: 50%;


		.ChatOneBackIcon2 {
			color: #fffff5;
		}
	}


}